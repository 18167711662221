const referral = {
  title: 'Referral',
  overview: 'Overview',
  stats: 'Statistics',
  bonus: 'Bonus',
  portfolio: 'Portfolio',
  copy: 'Copy',
  overviewData: {
    bonusPool: 'Bonus Pool',
    personnalCustomers: 'Personnal Active Sales',
    lastMonthRevenue: 'Last month revenue',
    totalRevenue: 'Total revenue',
    salesThisMonth: 'Sales this month',
    levelOne: 'Level 1',
    levelTwo: 'Level 2',
  },
  statsData: {
    newMonthlySell: 'New monthly sales',
    monthlyActiveCustomer: 'Monthly active customers',
    monthlyRevenue: 'Monthly revenue',
  },
  myTransactions: 'My transactions',
  noTransactions: 'No transactions to show',
  mySales: 'My sales',
  date: 'Date',
  user: 'User',
  sellBonus: 'Sell bonus',
  profit: 'Profit',
  myPortfolio: 'My portfolio',
  connectMetamask: 'Connect with Metamask',
  receivedAt: 'Received at',
  collected: 'Collected',
  fromYourSales: 'From your sales',
  connectSequence: 'Connect with Sequence',
  connectWalletConnect: 'Connect with WalletConnect',
  connected: 'Connected',
  actionsBalance: 'Budly actions balance',
  salesBalance: 'Sales balance',
  disconnect: 'Disconnect',
  myClients: 'My clients',
  bonusData: {
    qualificationRequirements: 'Qualification requirements',
    qualificationRequirementsDescription:
      'Each month, you can qualify for the 3 pools by making new sales. When the pool bonus is sent, the amount will be divided by the number of qualified users.',
    make: 'Make',
    newSalesCurrentMonth: 'new sales this month',
    missingSales: 'missing sales',
    validated: 'validated',
    bronze: 'Bronze',
    silver: 'Silver',
    gold: 'Gold',
    platinum: 'Platinum',
    unqualified: 'Unqualified',
  },
  sellBonusText1:
    'Your commission percentage increases with the number of your active monthly sales. A sale is active if it is paid in the current month.',
  sellBonusText2:
    'Annual subscriptions are considered active for 12 months, while monthly subscriptions extend their activity each renewal.',
  sellBonusText3: 'Maximize your income by retaining your customers.',
  paymentPercentage: 'Payment percentage',
  directActive: 'Direct active',
  bud_monney: 'Your BUDs make you money!',
  affiliate_dashboard: 'Affiliate dashboard',
  become_ambassador: 'Become an ambassador for 99€/year',
  nextPaymentIn: "Next payment in",
  days: "days",
  hours: "hours",
  minutes: "minutes",
}

export default referral
